.login-form {
  margin: 28px auto 0;
  width: 300px;
}

.login-logo {
  display: flex;
  height: 160px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
